<template>
  <div class="wrapper">
    <div v-if="loading">
      <shimmer-jackpot />
    </div>

    <div v-if="!results && !loading">
      <NoData
        :title="$t('noResultAvailable')"
        :description="$t('thereAreNoResult')"
      />
    </div>

    <JackpotResultCard
      v-else
      @slideUp="slideUp"
      v-for="result in results"
      :key="result.id"
      :resultsData="result"
    />
  </div>
</template>

<script>
import ShimmerJackpot from "../../../components/shimmer/shimmerJackpot/ShimmerJackpot.vue";
import NoData from "../../../components/ui/NoData.vue";
import JackpotResultCard from "./JackpotResultCard.vue";
import jpserve from "@/services/jpserve";

export default {
  name: "JackpotResult",
  components: {
    JackpotResultCard,
    ShimmerJackpot,
    NoData,
  },
  data() {
    return {
      activeTab: 0,
      loading: true,
      categoryID: null,
      results: [],
    };
  },
  methods: {
    getJackpotResult: async function (category_id) {
      this.loading = true;
      var path =
        process.env.VUE_APP_BASE_JACKPOT_URL +
        `/jackpot/${category_id}/results`;
      const res = await jpserve(path);
      if (res?.status === 200) {
        this.results = res.data;
      } else {
        this.results = [];
      }
      this.loading = false;
    },
  },
  mounted() {
    const category_id = this.$route.params.id;
    this.getJackpotResult(category_id);
    this.categoryID = category_id;
  },
  computed: {
    filteredLeaderboard() {
      console.log(this.results, " this.results this.results this.results");
      return "";
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 10px 20px;
  color: #000;
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.btn button {
  padding: 14px 0;
  width: 100%;
  font-size: 10px;
}
</style>
