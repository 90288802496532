<template>
  <div class="total-stake-wrapper">
    <div class="stake-summary">
      <span>
        <span class="stake_j">{{ $t("totalStake") }}:</span> {{ currency }}
        <span class="stake_j">100</span>
      </span>
      <span class="d-flex align-items-center">
        <span class="stake_j">{{ $t("combinations") }}:</span> 0
      </span>
    </div>
    <div class="stake-actions">
      <ChopbetButton variant="outline">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14.4999 11.9885C14.4999 11.9752 14.4932 11.9619 14.4932 11.9485C14.4866 11.8952 14.4799 11.8419 14.4599 11.7952C14.4332 11.7352 14.3999 11.6885 14.3599 11.6419C14.3599 11.6419 14.3599 11.6352 14.3532 11.6352C14.3066 11.5885 14.2532 11.5552 14.1932 11.5285C14.1332 11.5019 14.0666 11.4885 13.9999 11.4885L10.8866 11.5019C10.8866 11.5019 10.8866 11.5019 10.8799 11.5019C10.4799 11.5019 10.0932 11.3152 9.85324 10.9952L9.0399 9.94855C8.87324 9.72855 8.5599 9.68855 8.3399 9.86188C8.1199 10.0352 8.0799 10.3419 8.25324 10.5619L9.06657 11.6085C9.4999 12.1685 10.1799 12.5019 10.8866 12.5019H10.8932L12.7932 12.4952L12.3199 12.9685C12.1266 13.1619 12.1266 13.4819 12.3199 13.6752C12.4199 13.7752 12.5466 13.8219 12.6732 13.8219C12.7999 13.8219 12.9266 13.7752 13.0266 13.6752L14.3599 12.3419C14.4066 12.2952 14.4399 12.2419 14.4666 12.1819C14.4866 12.1152 14.4999 12.0485 14.4999 11.9885Z"
            fill="#0E0D0D"
          />
          <path
            d="M5.61333 4.46115C5.18 3.86115 4.48667 3.50781 3.74667 3.50781C3.74 3.50781 3.74 3.50781 3.73333 3.50781L2 3.51448C1.72667 3.51448 1.5 3.74115 1.5 4.01448C1.5 4.28781 1.72667 4.51448 2 4.51448L3.74 4.50781H3.74667C4.16667 4.50781 4.56 4.70781 4.8 5.04781L5.52 6.04781C5.62 6.18115 5.77333 6.25448 5.92667 6.25448C6.02667 6.25448 6.13333 6.22115 6.22 6.16115C6.44667 5.99448 6.49333 5.68115 6.33333 5.46115L5.61333 4.46115Z"
            fill="#0E0D0D"
          />
          <path
            d="M14.4933 4.0525C14.4933 4.03917 14.5 4.02583 14.5 4.01917C14.5 3.9525 14.4867 3.88583 14.46 3.82583C14.4333 3.76583 14.4 3.7125 14.3533 3.66583L13.02 2.3325C12.8267 2.13917 12.5067 2.13917 12.3133 2.3325C12.12 2.52583 12.12 2.84583 12.3133 3.03917L12.7867 3.5125L10.9667 3.50583C10.96 3.50583 10.96 3.50583 10.9533 3.50583C10.1867 3.50583 9.46667 3.88583 9.04 4.5325L4.78 10.9192C4.54 11.2792 4.13333 11.4992 3.7 11.4992H3.69333L2 11.4858C1.72667 11.4858 1.5 11.7058 1.5 11.9858C1.5 12.2592 1.72 12.4858 2 12.4858L3.7 12.4925C3.70667 12.4925 3.70667 12.4925 3.71333 12.4925C4.48667 12.4925 5.2 12.1125 5.62667 11.4658L9.88667 5.07917C10.1267 4.71917 10.5333 4.49917 10.9667 4.49917H10.9733L14 4.5125C14.0667 4.5125 14.1267 4.49917 14.1933 4.4725C14.2533 4.44583 14.3067 4.4125 14.3533 4.36583C14.3533 4.36583 14.3533 4.35917 14.36 4.35917C14.4 4.3125 14.44 4.26583 14.46 4.20583C14.48 4.15917 14.4867 4.10583 14.4933 4.0525Z"
            fill="#0E0D0D"
          />
        </svg>
        {{ $t("autoPick") }}
      </ChopbetButton>
      <ChopbetButton
        >{{ $t("placeBet") }} - {{ currency }} 100</ChopbetButton
      >
    </div>
    <div class="pick-summary">
      <div class="picked-matches">
        <InfoIcon /> <span>{{ $t("pickedMatches") }}</span>
      </div>
      <div @click="deleteAll()">
        <DeleteIcon />
      </div>
    </div>
  </div>
</template>

<script>
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import DeleteIcon from "../../../components/icons/DeleteIcon.vue";
import InfoIcon from "../../../components/icons/InfoIcon.vue";

export default {
  name: "TotalStake",
  components: {
    ChopbetButton,
    InfoIcon,
    DeleteIcon,
  },
  data() {
    return {
      currency: process.env.VUE_APP_CURRENCY,
    };
  },
  methods: {
    deleteAll() {
      this.$emit("deleteAll", false, 0);
    },
  },
};
</script>

<style scoped>
.total-stake-wrapper {
  width: 100%;
  padding: 8px 20px;
  height: 116px;
  position: fixed;
  bottom: 64px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 500;
  animation: slide-up 0.5s ease forwards;
  border-top: 1px solid #d1cfcf;
  color: #545151;
}

.stake-summary {
  display: flex;
  justify-content: space-between;
}

.stake-actions {
  width: 100%;
  display: flex;
  gap: 8px;
}
.stake-actions button {
  padding: 14px 0;
  width: 100%;
  font-size: 10px;
  svg {
    margin-top: -4px;
  }
}

.stake-actions button:first-child {
  width: 32%;
}

.stake-actions button:last-child {
  width: 67%;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.pick-summary {
  display: flex;
  justify-content: space-between;
}

.picked-matches {
  display: flex;
  align-items: center;
  gap: 2px;
}
.stake_j {
  font-weight: 500;
  color: #0e0d0d;
  font-size: 12px;
}
</style>
