import axios from "axios";

const jpserve = axios.create({
  baseURL: process.env.VUE_APP_BASE_JACKPOT_URL,
});

jpserve.defaults.headers.post["Content-Type"] = "application/json";
jpserve.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

jpserve.defaults.method = "get";

jpserve.interceptors.request.use(
  (config) => {
    const lang = localStorage.getItem("locale") || "en";
    config.headers["lang"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default jpserve;
