<template>
  <div>
    <div v-if="isSuccess" class="page_container">
      <div class="success_wrap">
        <div>
          <SuccessCard
            :title="$t('congratulations')"
            :description="$t('yourJackpotBetPlacedSuccessfully')"
          />
          <router-link :to="`/my-bets/${jackpotId}`">
            <ChopbetButton variant="primary">
              {{ $t("viewJackpotBetDetails") }}
            </ChopbetButton>
          </router-link>

          <div class="new_bet">
            <div class="heading">
              <p>{{ $t("placeNewJackpotBet") }}</p>
            </div>
            <div class="bet_option" @click="handleNewJackpot('new')">
              <RadioButton v-if="selectedOption !== 'new'" />
              <RadioChecked v-else />
              <div class="text">
                <p>{{ $t("createNewJackpotBet") }}</p>
                <p>{{ $t("makeJackpotBetSelectionsFromScratch") }}</p>
              </div>
            </div>
            <div class="bet_option" @click="handleNewJackpot('existing')">
              <RadioButton v-if="selectedOption !== 'existing'" />
              <RadioChecked v-else />
              <div class="text">
                <p>{{ $t("createExistingJackpotBet") }}</p>
                <p>{{ $t("usePreviousJackpotBetSelections") }}</p>
              </div>
            </div>
            <div class="bet_btn">
              <ChopbetButton
                variant="outline"
                @click="handleCloseSuccess"
                :size="'small'"
              >
                {{ $t("continue") }}
              </ChopbetButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="page_container">
        <BackComponent title="Jackpot" />
      </div>
      <PlayJackpotFilter @updateActive="handleActiveChange" />

      <div v-if="activeTab == 0" class="wrap">
        <div v-if="loading" class="page_container">
          <FixturesSkeletonLoader :count="10" />
        </div>
        <div v-if="noData && !loading">
          <NoData
            :title="$t('noFixtureAvailable')"
            :description="$t('thereAreNoFixture')"
          />
        </div>
        <div class="wrapper" v-if="games?.fixture">
          <JackpotMegabox :games="games" />
        </div>
        <GameBox
          v-for="(game, index) in games?.fixture"
          :key="game.id"
          :gameData="game"
          :index="index"
          @addOdd="addOdd"
          @openStake="openStake"
          @updateSlideup="handleSlideUp"
          :closeAll="activeOdd"
        />
        <PlaceBet
          :jackpotID="jackpotID"
          :marketID="marketID"
          :games="games"
          :action="action"
          @auto-pick-event="addRandomPicks"
          @delete-pick-event="deletePicks"
          @success="handleSuccess"
        />
      </div>
      <JackpotResult v-if="activeTab == 1" @openSlideUp="openResultsSlideUp" />
      <JackpotRules v-if="activeTab == 2" />
      <TotalStake v-if="stake" @deleteAll="deleteAll" />
    </div>
  </div>
</template>

<script>
import JackpotMegabox from "./components/JackpotMegabox.vue";
import GameBox from "./components/GameBox.vue";
import JackpotResult from "./components/JackpotResult.vue";
import JackpotRules from "./components/JackpotRules.vue";
import PlayJackpotFilter from "./components/PlayJackpotFilter.vue";
import TotalStake from "./components/TotalStake.vue";
import { fetchJackpotGames } from "../../actions/jackpot";
import PlaceBet from "./components/PlaceBet.vue";
import BackComponent from "../../components/ui/BackComponent.vue";
import NoData from "../../components/ui/NoData.vue";
import SuccessCard from "../../components/ui/SuccessCard.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import FixturesSkeletonLoader from "../../components/ui/FixturesSkeletonLoader.vue";
import RadioButton from "../../components/icons/RadioButton.vue";
import RadioChecked from "../../components/icons/RadioChecked.vue";

export default {
  name: "PlayJackpot",
  components: {
    RadioButton,
    RadioChecked,
    PlayJackpotFilter,
    JackpotMegabox,
    GameBox,
    JackpotRules,
    JackpotResult,
    TotalStake,
    PlaceBet,
    BackComponent,
    NoData,
    SuccessCard,
    ChopbetButton,
    FixturesSkeletonLoader,
  },
  data() {
    return {
      activeTab: 0,
      activeOdd: 0,
      slide: "",
      stake: false,
      fixtures: [],
      games: [],
      action: true,
      oddsGroup: [],
      isSuccess: false,
      loading: false,
      selectedOption: "new",

      statics: {
        country: "",
        league: "",
        date: "",
        time: "",
        homeTeam: "",
        awayTeam: "",
        homeOdds: "",
        drawOdds: "",
        awayOdds: "",
      },
    };
  },
  mounted() {
    this.fetchJackpotGame();
  },
  methods: {
    handleNewJackpot(selectedOption) {
      this.selectedOption = selectedOption;
    },
    async fetchJackpotGame() {
      this.loading = true;
      const params = this.$route.params.id;
      const res = await fetchJackpotGames(params);
      this.loading = false;
      if (res?.status === 200) {
        console.log(res?.data, "res?.data", this.selectedOption);
        this.games = res?.data || null;
      } else {
        this.games = [];
      }
      this.loading = false;
    },
    handleActiveChange(activeValue) {
      this.activeTab = activeValue;
    },
    handleCloseSlideUp(close) {
      this.slide = close;
    },
    openResultsSlideUp(slideValue) {
      this.slide = slideValue;
    },
    openStake(stake, odd) {
      this.stake = stake;
      this.activeOdd = odd;
    },
    deleteAll(stake, odd) {
      this.stake = stake;
      this.activeOdd = odd;
    },
    addOdd(odd, outcome_id) {
      this.action = !this.action;
      const copyPicks = JSON.parse(JSON.stringify(this.games));
      copyPicks.fixture.forEach((game) => {
        if (game.match_number === odd.match_number) {
          if (game.your_pick === outcome_id) {
            game.your_pick = null;
          } else {
            game.your_pick = outcome_id;
          }
        }
      });
      this.games = copyPicks;
    },
    addRandomPicks() {
      if (!this.games || !this.games.fixture) return;

      this.action = !this.action;
      const copyPicks = JSON.parse(JSON.stringify(this.games));
      copyPicks.fixture.forEach((game) => {
        if (game.outcomes && game.outcomes.length > 0) {
          const randomIndex = Math.floor(Math.random() * game.outcomes.length);
          const selectedOutcome = game.outcomes[randomIndex].outcome_id;
          game.your_pick =
            game.your_pick === selectedOutcome ? null : selectedOutcome;
        }
      });
      this.games = copyPicks;
    },

    deletePicks() {
      this.action = !this.action;
      const copyPicks = JSON.parse(JSON.stringify(this.games));
      copyPicks.fixture.forEach((game) => {
        game.your_pick = null;
      });
      this.games = copyPicks;
    },
    handleSuccess() {
      this.isSuccess = true;
      this.selectedOption = "new";
    },
    handleCloseSuccess() {
      this.isSuccess = false;
      if (this.selectedOption === "new") {
        this.fetchJackpotGame();
      }
    },
  },
  computed: {
    jackpotId() {
      return this.$route.params.id;
    },
    noData() {
      if (!this.games || !this.games.fixture) {
        return true;
      }
      return Array.isArray(this.games.fixture) && this.games.fixture.length < 1;
    },
  },
};
</script>

<style scoped src="./index.css"></style>
