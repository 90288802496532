<template>
  <div>
    <!-- <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp"> -->
    <!-- <div class="close_modal" @click="closeSlideUp()">
      <CloseIcon />
    </div> -->
    <div class="widgets">
      <iframe :src="statUrl" frameborder="0"></iframe>
    </div>
    <!-- </ChopbetSlideUp> -->
  </div>
</template>

<script>
// import CloseIcon from "../../auth/components/CloseIcon.vue";
// import ChopbetSlideUp from "../../../components/ui/ChopbetSlideUp.vue";

export default {
  name: "JackpotPredictions",
  props: {
    statData: {
      type: Number,
      required: true,
    },
  },
  components: {
    // CloseIcon,
    // ChopbetSlideUp,
  },
  data() {
    return {
      isSlideUpOpen: false,
      language: this.$i18n.locale,
      statUrl: `https://s5.sir.sportradar.com/choplifegamingmts/${this.$i18n.locale}/match/${this.statData.match_id}`,
    };
  },
  mounted() {
    this.loadWidget();
  },
  methods: {
    loadWidget() {},
    closeSlideUp() {
      this.$emit("close");
    },

    // openSlideUp() {
    //   this.isSlideUpOpen = true;
    // },
    // closeSlideUp() {
    //   this.isSlideUpOpen = false;
    // },
  },
};
</script>

<style scoped>
.close_modal {
  text-align: right;
  padding: 0.5rem;
  cursor: pointer;
  width: 60px;
  margin-right: 0;
  margin-left: auto;
}
.widgets {
  width: 100%;
  height: 500px;
}

.widgets iframe {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.click_away {
  flex-grow: 1;
}
.slide_wrapper {
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.slide_container {
  height: auto;
  max-height: 500px;
  border-radius: 30px 30px 0 0;
  background-color: #fff;
  width: 100%;
  color: #545151;
  animation: slideUp 0.5s ease forwards;
}
</style>
