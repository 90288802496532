<template>
  <div class="skeleton_wrapper">
    <div v-for="n in count" :key="n">
      <div class="skeleton-container">
        <div class="head">
          <div class="skeleton-item"></div>
          <div class="skeleton-item"></div>
        </div>

        <div class="content">
          <div>
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
          </div>
          <!-- <div>
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
          </div> -->
        </div>

        <div class="skeleton_button">
          <div class="possible_win">
            <div class="skeleton-item"></div>
            <!-- <div class="skeleton-item"></div> -->
          </div>
          <div class="skeleton-item"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./index.css" scoped></style>

<script>
export default {
  name: "ShimmerJackpot",
  props: {
    count: {
      type: Number,
      default: 8,
    },
  },
};
</script>
