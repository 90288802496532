<template>
  <div class="result_container">
    <div class="d-flex justify-content-between">
      <div class="result_details">
        <span>{{ resultsData.prize }} {{ fiatCurrency }}</span>
        <span class="picks">
          {{ $t("pick") }} {{ resultsData.number_of_games }} - 1x2
          <!-- - -->
          <!-- {{ resultsData.marketType }} -->
        </span>
        <span>
          {{ $t("ticketPrice") }}: {{ resultsData.prize }}
          {{ fiatCurrency }}
        </span>
        <span>{{
          resultsData.status === 0 ? $t("ended") : $t("ongoing")
        }}</span>
      </div>
      <span class="date"
        >{{ formattedDate(resultsData.end) }}
        <!-- • {{ resultsData.time }} -->
      </span>
    </div>
    <div @click="openSlideUp(resultsData.jackpot_category_id)" class="winnings">
      <div class="btn">
        <ChopbetButton>{{ $t("seeResults") }}</ChopbetButton>
      </div>
      <p>*** {{ $t("subjectToTax") }} 15% {{ $t("tax") }}</p>
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <span class="close" @click="closeSlideUp()"><CancelIcon /></span>
      <JackpotResults
        :fixture="resultsData.fixture"
        :resultsData="resultsData"
      />
    </ChopbetSlideUp>
  </div>
</template>

<script>
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import jpserve from "@/services/jpserve";
import ChopbetSlideUp from "../../../components/ui/ChopbetSlideUp.vue";
import JackpotResults from "./JackpotResults.vue";
import CancelIcon from "../../../components/icons/CancelIcon.vue";

export default {
  name: "JackpotResultCard",
  props: {
    resultsData: {
      type: Object,
      default() {
        return {
          fiatCurrency: process.env.VUE_APP_CURRENCY,
          id: "",
          amount: "",
          currency: "",
          numberofGames: "",
          marketType: "",
          ticketPrice: "",
          taxValue: "",
          date: "",
          time: "",
        };
      },
    },
  },
  components: {
    CancelIcon,
    JackpotResults,
    ChopbetSlideUp,
    ChopbetButton,
  },
  data() {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      isSlideUpOpen: false,
      fixtureData: [],
      loading: true,
      emptyJackpot: true,
    };
  },
  methods: {
    slideUp(isOpen) {
      this.isSlideUpOpen = isOpen;
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
    openSlideUp() {
      this.slideUp(true);
      this.$emit("slideUp", true);
    },
    getJackpotFixture: async function (category_id) {
      this.loading = true;
      var path =
        process.env.VUE_APP_BASE_JACKPOT_URL + `/jackpot/${category_id}`;
      const res = await jpserve(path);
      console.log(res, " --------", category_id);
      if (res?.status === 200) {
        this.fixtureData = res.data;
      } else {
        this.fixtureData = null;
      }
      this.loading = false;
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(date);
    },
  },
  computed: {
    // console.log(resultsData)
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.close {
  padding: 12px;
}
.result_container {
  padding: 12px 16px;
  background-color: var(--card-bg);
  min-height: 180px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.result_details {
  display: flex;
  flex-direction: column;
}
span {
  color: var(--text-color);
}
.result_details span:first-child {
  font-weight: 700;
  font-size: 14px;
}
.result_details span:nth-child(2) {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: -0.1px;
}
.result_details span:nth-child(3) {
  font-weight: 700;
  font-size: 10px;
  letter-spacing: -0.1px;
}
.result_details span:last-child {
  color: #057b1e;
  background-color: var(--jackpot-green);
  padding: 4px 8px;
  border-radius: 70px;
  font-weight: 500;
  width: 70%;
  text-align: center;
}

.winnings {
  text-align: center;
  width: 100%;
}

.winnings p,
.date,
.picks {
  color: var(--sub-text-color);
}

.btn button {
  padding: 14px 0;
  width: 100%;
  font-size: 10px;
}

.btn {
  width: 100%;
  padding: 0;
}
</style>
