<template>
  <div class="match-card">
    <div class="stake_details">
      <div class="match-teams">
        <span>{{ getHomeCompetitorName(picks.name) }}</span> <br />
        <span>{{ getAwayCompetitorName(picks.name) }}</span>
      </div>
      <div class="odds">
        <div
          v-for="(pick, index) in mappedPicks"
          :key="index"
          :class="isOddActive(pick) ? 'active_odd' : 'odd'"
        >
          <p>{{ pick.label }}</p>
        </div>
      </div>
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <JackpotPredictions :statData="gameData" @close="closeSlideUp" />
    </ChopbetSlideUp>
  </div>
</template>

<script>
import JackpotPredictions from "./JackpotPredictions.vue";
import ChopbetSlideUp from "../../../components/ui/ChopbetSlideUp.vue";
import { formatDateTime } from "../../../utils/formatter";

export default {
  name: "ResultBox",
  props: {
    gameData: {
      type: Object,
      required: true,
    },
    closeAll: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeOdd: null,
      selectedOdds: {},
      isSlideUpOpen: false,
      picks: this.gameData,
    };
  },
  components: {
    // StatsIcon,
    ChopbetSlideUp,
    JackpotPredictions,
    // PlaceBet,
  },
  watch: {
    closeAll(newVal) {
      if (newVal === 0) {
        this.activeOdd = 0;
      }
    },
    picks() {
      // console.log(this.picks, "picks");
      // this.picks = newVal;
    },
    gameData() {
      this.picks = this.gameData;
    },
  },
  methods: {
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },
    formatDateTime,
    toggleOdd(oddIndex, oddValue) {
      if (this.activeOdd !== null) {
        // Check for existing selection
        if (this.activeOdd === oddIndex) {
          // Deselect on same click
          this.activeOdd = null;
          this.removePick();
          this.$nextTick(() => {
            this.$root.autoRefreshUI("removePick");
          });
        } else {
          // Select on new click
          this.activeOdd = oddIndex;
          this.addJackpotPick(oddIndex, oddValue); // Use addJackpotPick here
          this.$nextTick(() => {
            this.$root.autoRefreshUI("addJackpotPick");
          });
        }
      } else {
        // No selection, select new
        this.activeOdd = oddIndex;
        this.addJackpotPick(oddIndex, oddValue); // Use addJackpotPick here
        this.$nextTick(() => {
          this.$root.autoRefreshUI("addJackpotPick");
        });
      }
    },
    addPick(pick, outcomeId) {
      const copyPicks = JSON.parse(JSON.stringify(this.picks));
      if (copyPicks.your_pick === outcomeId) {
        copyPicks.your_pick = null;
      } else {
        copyPicks.your_pick = outcomeId;
      }
      this.picks = copyPicks;
      // this.$emit("auto-pick-event", {});
      this.$emit("addOdd", copyPicks, outcomeId);
    },
    addJackpotPick(oddIndex, oddValue) {
      const pick = {
        sport_id: this.gameData.sportId,
        match_id: this.gameData.matchId,
        market_name: this.getMarketName(oddIndex),
        market_id: this.getMarketId(oddIndex),
        home_team: this.gameData.homeTeam,
        away_team: this.gameData.awayTeam,
        producer_id: this.gameData.producerId,
        outcome_id: oddIndex,
        odd: oddValue,
        live: this.gameData.live,
      };

      console.log("Adding jackpot pick:", pick);
      this.$root.addJackpotPick(
        pick.sport_id,
        pick.match_id,
        pick.market_name,
        pick.market_id,
        pick.home_team,
        pick.away_team,
        pick
      );

      this.selectedOdds = pick;
      console.log("Jackpot Pick added:", this.selectedOdds);
    },
    removePick() {
      // console.log("Removing pick:", this.selectedOdds);
      this.selectedOdds = {};
      this.$root.autoRefreshUI("removePick");
    },

    isOddActive(pick) {
      return pick.won === 1; // Check if this pick has `won: 1` to set active
    },

    // Helper function to get market name (update logic based on your requirement)
    getMarketName(oddIndex) {
      if (oddIndex === 1) return "Home Win";
      if (oddIndex === 2) return "Draw";
      if (oddIndex === 3) return "Away Win";
      return "Unknown";
    },

    // Helper function to get market id (update logic based on your requirement)
    getMarketId(oddIndex) {
      return oddIndex; // Update this as needed based on your market logic
    },

    openSlideUp() {
      this.isSlideUpOpen = true;
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
  },
  mounted() {
    console.log(this.gameData, "gameDatagameDatagameData");
  },
  computed: {
    mappedPicks() {
      const outcomeLabels = ["1", "X", "2"]; // Labels for the 3 outcomes
      return this.picks.results.map((pick, index) => ({
        label: outcomeLabels[index], // Assigning "1", "X", "2"
        odds: pick.odds ? parseFloat(pick.odds).toFixed(2) : "N/A", // Format odds
        won: pick.won, // Use the `won` property for active state
        outcome_id: pick.outcome_id, // Keep outcome_id for reference
      }));
    },
  },
};
</script>

<style scoped src="./styles/resultBox.css"></style>
