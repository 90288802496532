<template>
  <div class="mega_box">
    <div class="win_box">
      <span>ChopBet {{ games.name }}</span>
      <span class="win"
        >{{ this.$t("win") }} {{ games.prize }}{{ fiatCurrency }}</span
      >
    </div>
    <div class="countdown_box">
      <span>{{ this.$t("expiryDate") }} - {{ formattedTargetDate }}</span>
      <div class="countdown">
        <div>
          <span class="count">{{ countdown.days || 0 }}</span>
          <span>{{ this.$t("day") }}</span>
        </div>
        <div>
          <span class="count">{{ countdown.hours || 0 }}</span>
          <span>{{ this.$t("hours") }}</span>
        </div>
        <div>
          <span class="count">{{ countdown.minutes || 0 }}</span>
          <span>{{ this.$t("minutes") }}</span>
        </div>
        <div>
          <span class="count">{{ countdown.seconds || 0 }}</span>
          <span>{{ this.$t("seconds") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JackpotMegabox",

  props: {
    games: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      targetDate: new Date("2024-09-26 20:00:00"),
    };
  },
  computed: {
    formattedTargetDate() {
      const day = new Date(this.games.end)
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (new Date(this.games.end).getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const year = new Date(this.games.end).getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    calculateTimeRemaining() {
      const now = new Date();
      const timeDifference = new Date(this.games.end) - now;

      if (timeDifference <= 0) {
        clearInterval(this.countdownInterval);
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    },
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        this.countdown = this.calculateTimeRemaining();
      }, 1000);
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
};
</script>

<style scoped>
.wrapper {
  padding: 10px 20px;
}
.mega_box {
  padding: 12px;
  border-radius: 5px;
  background-color: var(--card-bg);
  color: var(--sub-text-color);
  display: flex;
  flex-direction: column;
}
.win {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color);
}
.win_box {
  height: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.countdown_box {
  background-color: var(--background-color);
  color: var(--sub-text-color);
  border-radius: 5px;
  text-align: center;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.countdown {
  width: 100%;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.countdown div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.count {
  font-weight: 700;
  color: var(--text-color);
  font-size: 14px;
}
</style>
