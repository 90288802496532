<template>
  <div class="wrapper">
    <span class="header">{{ this.$t("jackpotRules") }}</span>
    <div class="rule" :key="rule.id" v-for="rule in rules">
      <span class="sub_header3">{{ rule.subtitle3 }}</span>
      <span class="sub_header">{{ rule.subtitle1 }}</span>
      <p v-html="formatText(rule.label)"></p>
      <span class="sub_header2">{{ rule.subtitle2 }}</span>
      <span class="sub_header3">{{ rule.subtitle4 }}</span>
      <p v-html="formatText(rule.label2)"></p>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "JackpotRules",
  // data() {
  //   return {
  //     rules: [
  //       {
  //         id: 1,
  //         title: this.$t("jackpotTitle10"),
  //         subtitle1: this.$t("jackpotTitle10"),
  //         subtitle2: this.$t("online"),
  //         label: this.$t("jackpotDescription"),
  //         label2: this.$t("betSelectionProcess"),
  //       },
  //       {
  //         id: 2,
  //         title: this.$t("jackpotTitle13"),
  //         subtitle1: this.$t("jackpotTitle13"),
  //         subtitle2: this.$t("online"),
  //         label: this.$t("jackpotAmount"),
  //         label2: this.$t("makingSelection"),
  //       },
  //       {
  //         id: 3,
  //         subtitle3: this.$t("chop13JackpotBonus"),
  //         subtitle4: this.$t("doubleCombinationBetTitle"),
  //         label: this.$t("additionalPrizesEligibility"),
  //         label2: this.$t("doubleCombinationBetExplanation"),
  //       },
  //     ],
  //   };
  // },
  methods: {
    formatText(text) {
      return text.replace(/\/n/g, "<br><br>");
    },
  },
  computed: {
    rules() {
      return [
        {
          id: 1,
          title: this.$t("jackpotTitle10"),
          subtitle1: this.$t("jackpotTitle10"),
          subtitle2: this.$t("online"),
          label: this.$t("jackpotDescription"),
          label2: this.$t("betSelectionProcess"),
        },
        // {
        //   id: 2,
        //   title: this.$t("jackpotTitle13"),
        //   subtitle1: this.$t("jackpotTitle13"),
        //   subtitle2: this.$t("online"),
        //   label: this.$t("jackpotAmount"),
        //   label2: this.$t("makingSelection"),
        // },
        // {
        //   id: 3,
        //   subtitle3: this.$t("chop13JackpotBonus"),
        //   subtitle4: this.$t("doubleCombinationBetTitle"),
        //   label: this.$t("additionalPrizesEligibility"),
        //   label2: this.$t("doubleCombinationBetExplanation"),
        // },
      ];
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.wrapper {
  padding: 20px;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.14px;
}
.rule {
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* background-color: red; */
}
.sub_header {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.14px;
}
.sub_header2 {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.14px;
}
.sub_header3 {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.14px;
}
</style>
